import React from 'react';
import Logo from './Logo/Logo';
import Search from './SearchBar/SearchBar';
import DropdownUser from './DropdownUser/DropdownUser';
import DropdownCart from './DropdownCart/DropdownCart';
import NavBar from '../NavBar';
import HeaderResponsive from '../HeaderResponsive';
import NavBarResponsive from '../NavBarResponsive';
import ChangeButton from './ChangeButton/ChangeButton';
import '../../scss/common.scss';
import { getItemSession } from '../../utils';
import NewNavbar from '../NewNavBar/NewNavbar';

const Header = () => {
  const dados = JSON.parse(getItemSession('_dados'));
  const unicaFilial = getItemSession('_unicaFilial') === 'true';


  return (
    <>
      <header className="header desktop">
        <div className="container2 header__topo d-none d-lg-flex container-head">
          <div className="col-2" id='col-logo'>
            <Logo />
          </div>
          
            {/* <div className="col-3" id="row-align">
            {unicaFilial && (
              <ChangeButton />
            )}
            </div> */}
          
          <div className="col-8" id='col-search'>
            <Search />
          </div>
          <div className="col-2" id="col-user">
            <DropdownUser />
          </div>
          {!dados.ApenasVisualizacao && (
            <div className="col-2" id="col-cart">
              <DropdownCart />
            </div>
          )}
        </div>
        <HeaderResponsive />
        <NewNavbar/>
      </header>
      <NavBarResponsive />
    </>
  );
}

export default Header;
